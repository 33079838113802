<template>
  <div
    style="position: relative; height: calc(100vh - 40px); overflow-y: scroll"
  >
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div @click="handleBack">
      <i class="el-icon-arrow-left"></i>
      <span>返回</span>
    </div>

    <div class="renew_detail_head">
      <p class="renew_detail_head_status">待续费</p>
      <div class="renew_detail_head_status_d">
        <p>
          流量有效期：<span>{{ this.detailData.untilTime }}</span>
        </p>
        <p>
          趣宝盒ID：<span>{{ this.detailData.hardwareId }}</span>
        </p>
        <p>
          网络连接：<span>{{ this.detailData.status }}</span>
        </p>
        <p>
          剩余天数：<span>{{ this.detailData.remainingDays }}</span>
        </p>
        <p>
          是否为新盒子：<span>{{
            this.detailData.isNew == true ? "是" : "否"
          }}</span>
        </p>
      </div>
    </div>

    <div class="renew_detail_head" style="height: 67px">
      <p class="renew_detail_head_status">用户信息</p>
      <div class="renew_detail_head_status_d">
        <p>
          用户名：<span>{{ this.detailData.realName }}</span>
        </p>
        <p>
          手机号：<span>{{ this.detailData.phone }}</span>
        </p>
      </div>
    </div>

    <div class="renew_detail_history">
      <p>续费历史</p>

      <el-table
        :data="tableData"
        style="width: 95%"
        border
        :header-cell-style="{ background: '#e5e5e5', color: '#4b4b4b' }"
      >
        <el-table-column type="index" label="序号" width="70" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="续费时间" align="center">
        </el-table-column>
        <el-table-column
          prop="lengthOfTime"
          label="续费时长"
          align="center"
          width="350"
        >
        </el-table-column>
        <el-table-column prop="payType" label="优惠金额" align="center">
          <template slot-scope="scope">
            {{
              scope.row.payType
                ? scope.row.payType == 1
                  ? `$ ${scope.row.amountTotal}`
                  : `$ ${scope.row.p95Amount}`
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="payType" label="实收款" align="center">
          <template slot-scope="scope">
            {{
              scope.row.payType
                ? scope.row.payType == 1
                  ? `$ ${scope.row.amountTotal}`
                  : `$ ${scope.row.p95Amount}`
                : ""
            }}
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
import { format_time_date } from "@/util/funBusiness";

export default {
  components: { Breadcrumb, Pagination },

  data() {
    return {
      tableData: [],
      detailData: {},

      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "趣宝盒数据",
        },
        {
          name: "流量续费",
        },
        {
          name: "已续费",
        },
      ], // 面包屑数据

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 50, 100, 200, 500, 1000],
    };
  },

  mounted() {
    this.detailData = JSON.parse(localStorage.getItem("TrafficRenewalDetail"));

    showLoading();
    const opt = {
      url: reqUrl.getHardwareUntilTimeDetail,
      method: "POST",
      params: JSON.stringify({
        userId: this.detailData.userId,
      }),
      resFunc: (res) => {
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          console.log(res.data);
          this.tableData = res.data.data.list;

          if (res.data.data.list) {
            let arrList = [];
            res.data.data.list.map((item) => {
              let obj = {};
              obj = { ...item.payOrderInfo };
              obj.lengthOfTime = item.lengthOfTime;
              arrList.push(obj);
              if (obj.createTime) {
                obj.createTime = format_time_date(obj.createTime);
              }
            });
            this.tableData = arrList;
          }

          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    handleBack() {
      this.$router.push({
        path: "/TradeInVaitRenew",
      });
      localStorage.removeItem("TrafficRenewalDetail");
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getHardwareUntilTimeDetail,
        method: "POST",
        params: JSON.stringify({
          userId: this.detailData.userId,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            console.log(res.data);
            this.tableData = res.data.data.list;

            if (res.data.data.list) {
              let arrList = [];
              res.data.data.list.map((item) => {
                let obj = {};
                obj = { ...item.payOrderInfo };
                obj.lengthOfTime = item.lengthOfTime;
                arrList.push(obj);
                if (obj.createTime) {
                  obj.createTime = format_time_date(obj.createTime);
                }
              });
              this.tableData = arrList;
            }

            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },
  },
};
</script>

<style lang="scss" scoped>
.renew_detail_head {
  width: 90%;
  height: 100px;
  background: rgba(247, 248, 250, 1);
  border-radius: 10px;
  margin-top: 10px;
  padding: 20px;

  .renew_detail_head_status {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .renew_detail_head_status_d {
    display: flex;
    flex-wrap: wrap;

    p {
      width: 320px;
      color: #8b8a8a;
      font-size: 14px;
      margin-bottom: 10px;
    }
    span {
      color: #000;
    }
  }
}

.renew_detail_history {
  margin: 20px 0;

  p {
    margin-bottom: 20px;
  }
}
</style>
